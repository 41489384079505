import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgPeacockLogo from '../img/logo_peacock.jpg'
import imgSwanLogo from '../img/logo_swan.jpg'
import imgNeggin from '../img/neggin.jpg'

const IndexPage = () => (
  <Layout pageName="home">
    <SEO title="Home" />
    <div id="primary" className="content-area">
      <main id="main" className="site-main" role="main">
        <article id="post-35" className="post-35 page type-page status-publish hentry">
          <div id="layout-book">
            <a href="/about-us"><h4>Dr Neggin<br/> Heidari-Robinson</h4>
              <div>
                <div className="gleam">&nbsp;</div>
                <img alt="Dr Neggin Heidari-Robinson" title="Dr Neggin Heidari-Robinson"
                     src={imgNeggin} />
              </div>
            </a>
          </div>

          <div className="home-authors">
            <ul>
              <li>
                <ul className="horizontal">
                  <li className="author-link">
                    <a href="/men">
                      <span className="author-name">For Men</span>
                      <div><img alt="Oxford &amp; Dorking Peacock logo"
                                src={imgPeacockLogo} />
                      </div>
                    </a>
                  </li>
                  <li className="author-link">
                    <a href="/women">
                      <span className="author-name">For Women</span>
                      <div><img alt="Oxford &amp; Dorking Swan logo"
                                src={imgSwanLogo} />
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>


        </article>
      </main>
    </div>
  </Layout>
)

export default IndexPage
